// import React, { Component } from "react";
import React, { useState, useEffect, Component } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useParams,
	useLocation,
} from "react-router-dom";

import ServiceSuspended from "./ServiceDenied";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}

		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route exact path='/' element={<ServiceSuspended />} />

					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default index;
