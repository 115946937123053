import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	Button,
	Picker,
	StyleSheet,
	Image,
} from "react-native";

// Import your logo here
// import logo from './path-to-logo.png';

class ServiceSuspended extends Component {
	render() {
		return (
			<div style={styles.container}>
				{/* Logo */}
				<Image
					style={{
						width: 250,
						height: 200,
						resizeMode: "contain",
					}}
					source={require("./signitory.webp")}
					// style={styles.logo}
				/>

				{/* Notice */}
				<div style={styles.notice}>
					<span style={styles.emoji}>⚠️</span>
					<h1 style={styles.title}>Service Suspended</h1>
					<p style={styles.message}>
						We're sorry, but our services are currently suspended.
						Please check back later for updates.
					</p>
				</div>
			</div>
		);
	}
}

const styles = {
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100vh",
		backgroundColor: "#f0f4f8", // Light modern background color
		color: "#333", // Dark text color for good contrast
		fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
	},
	logo: {
		width: "150px",
		marginBottom: "30px",
	},
	notice: {
		textAlign: "center",
		backgroundColor: "#fff", // White background for the notice box
		padding: "20px",
		borderRadius: "8px",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
	},
	emoji: {
		fontSize: "2em",
		marginBottom: "10px",
	},
	title: {
		fontSize: "24px",
		margin: "10px 0",
	},
	message: {
		fontSize: "16px",
		color: "#666", // Lighter text color for the message
	},
};

export default ServiceSuspended;
